<template>
  <v-container
    id="crud"
    fluid
    tag="section"
    class="px-6 mx-0"
    style="font-family: 'Inter', sans-serif"
  >
  <!--
    <div v-if="$store.state.carrito.data.length == 0">
      <div
        style="height: 320px"
        class="carritoMsg__div px-2 d-flex flex-column justify-center align-items-center"
      >
        <div style="position: relative; display: inline-block">
          <v-icon
            color="text-center"
            style="font-size: 5rem; top: 0; left: 45%; pointer-events: none"
            >mdi-cart-outline</v-icon
          >

          <v-btn
            class="text-center primary"
            dark
            elevation="0"
            fab
            icon
            x-small
            style="position: absolute; top: 0; left: 47%; pointer-events: none"
          >
            <span style="font-size: 14px; padding: 5px">0</span>
          </v-btn>
        </div>
        <br />
        <p
          style="line-height: 1; font-weight: 500"
          class="display-1 text-center"
        >
          Tu carrito está vacio
        </p>
        <small style="line-height: 1; color: #999" class="text-center">
          Actualmente no hay productos en su carrito.<br />Puede seguir
          comprando y agregar los productos deseados.
        </small>
      </div>
    </div>
    <div v-else>
    -->
    <div v-if="!datosCargados">
      <v-card
        outlined
        class="elevation-3 d-flex justify-center align-center"
        style="height: 300px"
      >
        <v-progress-circular indeterminate color="primary">
          <br />
          <small class="text-center" style="margin-top: 105px !important">
            Cargando datos...
          </small>
        </v-progress-circular>
      </v-card>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="8" class="pl-4 pr-4">
          <v-card
            outlined
            class="elevation-3 d-flex justify-center align-center"
            v-if="isLoading"
            style="height: 300px"
          >
            <v-progress-circular indeterminate color="primary"
              ><br /><small
                class="text-center"
                style="margin-top: 105px !important"
                >obteniendo datos...</small
              ></v-progress-circular
            >
          </v-card>

          <v-card outlined class="elevation-3" v-else>
            <v-row  class="pa-6" >
              <v-col cols="2" v-if="facturaSeleccionada.db">
                <v-img
                  :src="'/img/'+ empresa[facturaSeleccionada.db].logo"
                  contain
                  max-width="100"
                  class="mb-4"
                ></v-img>
              </v-col>
              <v-col cols="2" v-else>
                <v-img
                  :src="'/img/mj_logo.png'"
                  contain
                  max-width="100"
                  class="mb-4"
                ></v-img>
              </v-col>
              <v-col  cols="7">
                <!-- <div>{{ (facturaSeleccionada.PointOfIssueCode)?empresa[facturaSeleccionada.PointOfIssueCode].empresa : (facturaSeleccionada.convenio_marco)? facturaSeleccionada.convenio_marco:'' }}</div> -->
                <div style="font-size: 0.8rem;" v-if="facturaSeleccionada.isBoletaAnmac">ACARA</div>
                <div style="font-size: 0.8rem;">{{ (facturaSeleccionada.mostrarCM==0)?empresa[facturaSeleccionada.db].empresa : (facturaSeleccionada.convenio_marco)? facturaSeleccionada.convenio_marco:'' }}</div>

                <div style="font-size: 0.8rem; color: #999">
                  Av. Cordoba 3371 (1187), CABA
                </div>
                <div style="font-size: 0.8rem; color: #999">
                  (1187) C.A.B.A.
                </div>

              </v-col>
              <v-col cols="3" class="d-flex flex-column align-end">
                <div
                  style="
                    width: 250px;
                    background: #f4f5f6;
                    border-radius: 5px;
                    text-align: center;
                    font-weight: 700;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                >
                  Nota de pedido N°{{ facturaSeleccionada.U_RefPedido }}
                </div>
                <!-- Número Legal (Factura FC XXXXX-XXXXXXXX) -->
                <div
                  v-if="[2, 4, 5].includes(facturaSeleccionada.U_FPago)"
                  style="
                  width: 250px;
                  background: #eee;
                  border-radius: 5px;
                  text-align: center;
                  font-size: 0.8rem;
                  margin-top: 5px;
                  padding: 5px;
                "
                >
                  Factura: FC {{ numeroLegal }}
                </div>
                <div style="font-size: 0.8rem; color: #999">
                  Monto Total
                </div>
                <div style="font-size: 1.4rem; font-weight: 700; text-align: center">
                  {{ (facturaSeleccionada)?'$'+$formatMoney(facturaSeleccionada.DocTotal):'' }}
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
                md="12"
                style="font-weight: 700; text-align: center"
              >
                {{ facturaSeleccionada.CardCode }} -
                {{ facturaSeleccionada.CardName }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="12"
                style="font-weight: 700; text-align: center; color: #999"
              >
                {{ (facturaSeleccionada.DocDate)? $formatDate(facturaSeleccionada.DocDate, true):'' }} {{(facturaSeleccionada.DocTime)? facturaSeleccionada.DocTime:'' }}
              </v-col>
            </v-row>

            <v-row class="pa-0 ml-8">
              <div
                class="px-4"
                style="
                  background-color: #e5f3ff;
                  border-radius: 8px;
                  width: 95% !important;
                "
              >
                <div
                  class="text-caption font-weight-bold py-1"
                  style="font-size: 1rem !important"
                >
                  Dirección de facturación
                </div>

                <div class="text-caption grey--text py-1">
                  {{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.ShipToStreet:'' }}
                  <span v-if="facturaSeleccionada.isBoletaAnmac"> {{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.ShipToStreetNo:'' }} </span>
                </div>
                <div class="text-caption grey--text py-1">
                  <span v-if="facturaSeleccionada.isBoletaAnmac"> ({{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.ShipToZipCode:'' }} ) </span> {{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.BillToCity:'' }} <span v-if="facturaSeleccionada.isBoletaAnmac"> {{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.BillToCountry:'' }} </span>
                </div>
                <div class="text-caption grey--text py-1">
                  C.U.I.T {{ facturaSeleccionada.FederalTaxID }}
                </div>
              </div>
            </v-row>
            <v-row class="pa-0 ml-6 mr-6 mt-6">
              <!-- Tabla de productos -->
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr style="background-color: #fafafa">
                      <th class="table-th text-left">Item</th>
                      <th class="table-th text-left">Descripción</th>
                      <th class="table-th text-left" v-if="facturaSeleccionada.isBoletaAnmac && [2, 4, 5].includes(facturaSeleccionada.U_FPago)">Desde</th>
                      <th class="table-th text-left" v-if="facturaSeleccionada.isBoletaAnmac && [2, 4, 5].includes(facturaSeleccionada.U_FPago)">Hasta</th>
                      <th v-if="columDominio" class="table-th text-left">Dominio/Form</th>
                      <th class="table-th text-left">Cantidad</th>
                      <th class="table-th text-right">Precio</th>
                      <th class="table-th text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i, index) in facturaSeleccionada.DocumentLines"
                      :key="index"
                    >
                      <td class="table-td">{{ i.ItemCode }}</td>
                      <td class="table-td">{{ i.ItemDescription }}</td>
                      <td class="table-td" v-if="facturaSeleccionada.isBoletaAnmac && [2, 4, 5].includes(facturaSeleccionada.U_FPago)">{{ i.U_SEIDORAR_DistNuD }}</td>
                      <td class="table-td" v-if="facturaSeleccionada.isBoletaAnmac && [2, 4, 5].includes(facturaSeleccionada.U_FPago)">{{ i.U_SEIDORAR_DistNuH }}</td>
                      <td v-if="columDominio" class="table-td">{{ i.U_SEIDORAR_DistNuD }}</td>
                      <td class="table-td ">{{ i.Quantity }}</td>
                      <td class="table-td text-right">
                        ${{ $formatMoney(i.Price) }}
                      </td>
                      <td class="table-td text-right">
                        ${{ $formatMoney(i.LineTotal) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>


            <v-row class="pa-0 ml-6 mr-6 mt-6">
              <v-col cols="12" md="6" class="offset-6 text-right">
                 <!--
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="grey--text" style="font-size: 0.8rem">
                      Subtotal
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="text-right font-weight-bold pa-1"
                    >$ {{ $formatMoney(facturaSeleccionada.subtotal) }}
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="grey--text" style="font-size: 0.8rem">
                      Impuestos sobre las ventas
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="text-right font-weight-bold pa-1"
                  >
                    $ {{ $formatMoney(facturaSeleccionada.impuestos) }}
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 borderBottom">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="grey--text" style="font-size: 0.8rem">
                      Manipulación y Envío
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="text-right font-weight-bold pa-1"
                    >${{ $formatMoney(facturaSeleccionada.envio) }}
                  </v-col>
                </v-row>
-->
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="font-weight-bold text-subtitle-1">
                      Total a pagar
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" class="text-right pa-1">
                    <div class="font-weight-bold text-subtitle-1">
                      ${{ $formatMoney(facturaSeleccionada.DocTotal) }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="facturaSeleccionada.isBoletaAnmac && [2, 4, 5].includes(facturaSeleccionada.U_FPago)" class="pa-0 ml-8">
              <v-col cols="12" md="12" sm="12">
                <div>
                  <div class="text-caption font-weight-bold py-1" style="font-size: 1rem !important">Recibo: RC {{ facturaSeleccionada.payment.DocNum }}</div>
                  <div class="text-caption">Recibimos de Uds.: ${{ $formatMoney(facturaSeleccionada.payment.SumApplied) }}</div>
                  <div class="text-caption">Son Pesos: {{ facturaSeleccionada.payment.SumAppliedLetters }}</div>
                  <div class="text-caption">{{ facturaSeleccionada.U_RefPedido }} - ${{ $formatMoney(facturaSeleccionada.payment.SumApplied) }} - {{ $formatDate(facturaSeleccionada.payment.DocDate, true) }}</div>
                  <div class="text-caption font-weight-bold py-1" style="font-size: 1rem !important">
                    <span v-if="facturaSeleccionada.U_FPago == 4"> TRANSF DEST.: </span>
                    {{ facturaSeleccionada.payment.CreditCardName }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ml-8" v-if="!facturaSeleccionada.isBoletaAnmac">
              <v-col cols="12" md="7" class="text-left pa-1">
                <div class="font-weight-bold text-subtitle-1">
                  Forma de entrega: Envío por Correo.
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ml-8">
              <v-col cols="12" md="12" sm="12">
                <div class="font-weight-bold text-subtitle-1">
                  <span v-if="!facturaSeleccionada.isBoletaAnmac">
                    {{(facturaSeleccionada.U_Deleg) && (facturaSeleccionada.U_Deleg!='false')?'DELEGACION: '+facturaSeleccionada.U_Deleg:''}}
                  </span>
                  <span v-if="facturaSeleccionada.isBoletaAnmac">
                    {{(facturaSeleccionada.U_DelegName) && (facturaSeleccionada.U_DelegName!='false')?facturaSeleccionada.U_DelegName:''}}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ml-8" v-if="facturaSeleccionada.isBoletaAnmac">
              <v-col cols="12" md="12" sm="12">
                <div class="text-subtitle-1">
                  {{(facturaSeleccionada.U_DelegAddress) && (facturaSeleccionada.U_DelegAddress!='false')?facturaSeleccionada.U_DelegAddress:''}}
                </div>
              </v-col>
            </v-row>
            <v-row v-if="facturaSeleccionada.type	== 'oInvoices'" class="pa-0 ml-8">
              <div
                class="px-4"

              >
                <div
                  class="text-caption font-weight-bold py-1"
                  style="font-size: 1rem !important"
                >
                Este documento no es una factura
                </div>
                </div>
            </v-row>

            <!--Inicio BNA EFECTIVO y cheque -->
            <v-row  v-if="facturaSeleccionada.U_FPago	== 1 || facturaSeleccionada.U_FPago	== 3">
              <v-col cols="12" md="12" class="">
                <table id="deposito" class="table-5" style="">
                  <tbody>

                    <tr v-for="n in 2" :key="n">
                      <td style="width:40px; border: 1px solid #000; text-align: center; vertical-align: bottom; max-width: 40px; overflow: hidden;">
                          <span v-if="n==1" style="display: block; transform: rotate(270deg); white-space: nowrap;margin-bottom: 30px;">
                            Comprobante Depositante
                          </span>
                          <span v-else style="display: block; transform: rotate(270deg); white-space: nowrap;margin-bottom: 30px;">
                            Comprobante Banco
                      </span>

                      </td>
                      <td style=" border: 1px solid #000;">
                          <table style="padding-left:5px">
                              <tr>

                                  <td style="width:430px;padding-top:1px;">
                                      <span class="bold" style="font-size:0.7rem">
                                          BANCO DE LA NACION ARGENTINA
                                      </span>

                                  </td>

                                  <td style="margin-top: 2px;margin-right:0px">
                                      <span class="bold" style="font-size:0.6rem;text-align:end;border: 1px solid #000;padding: 5px;padding-right:10px;margin-bottom: 0px;">
                                          Boleta de Depósito N° {{ facturaSeleccionada.U_RefPedido }}
                                      </span>
                                  </td>
                              </tr>
                          </table>

                          <table class="bold" style="padding-left:7px;font-size:0.7rem;">
                              <tr>
                                  <td style="font-weight: bold;">
                                  {{ facturaSeleccionada.convenio_marco }}
                                  </td>

                              </tr>
                              <tr>
                                  <td style="width:405px;padding-top:1px;">

                                      Convenio Recaudacion N° {{ facturaSeleccionada.convenio_recaudacion }}
                                  </td>
                                  <td style = "width: 20px;"></td>
                                  <td rowspan="6" style="width: 155px; text-align:center; border: 1px solid #000; position: relative; vertical-align: top;">
                                      <div style="position: absolute; top: 5px; left: 0; right: 0;">
                                          {{ facturaSeleccionada.deposit_date }} {{ facturaSeleccionada.deposit_time }}
                                      </div>
                                      <div style="position: absolute; top: 110px; left: 0; right: 0; font-weight: bold;">
                                          Sello Caja Banco
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Depositante: {{ facturaSeleccionada.CardName }} C.U.I.T: {{ facturaSeleccionada.CardCode }}
                                  </td>
                                  <td></td>
                              </tr>
                              <tr>
                                  <td>
                                      <table class="table-5">
                                          <tr>
                                              <td class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                  Vencimiento: {{ facturaSeleccionada.vencimiento }}
                                              </td>
                                              <td v-if="facturaSeleccionada.U_FPago == 6" class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                      Importe:
                                                  ${{ $formatMoney(facturaSeleccionada.DocTotal)}}
                                              </td>
                                              <td v-else-if="facturaSeleccionada.U_FPago == 1" class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                      Efectivo:
                                                  ${{ $formatMoney(facturaSeleccionada.DocTotal)}}
                                              </td>
                                              <td v-else class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                  Cheque:

                                              ${{ $formatMoney(facturaSeleccionada.DocTotal) }}
                                          </td>
                                          </tr>
                                      </table>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      <!-- Código de barras -->
                                      <div style="text-align: center; margin-top: 10px;">
                                          <!-- Mostrar la imagen del código de barras -->
                                          <img :src="facturaSeleccionada.codigoBarrasImg" alt="Código de barras" style="width: 100%; max-width: 260px;" />
                                      </div>
                                      <p style="text-align: center; font-size: 15px; font-weight: normal; margin-top: -5px;">
                                          <!-- Mostrar el texto del código de barras -->
                                          {{ facturaSeleccionada.codigoBarras }}
                                      </p>
                                  </td>
                              </tr>
                          </table>

                              <p v-if="facturaSeleccionada.U_FPago	== 1" style="padding-left:15px; font-size: 0.6rem;height:20px;">Son Pesos: {{ facturaSeleccionada.PriceLetters }}</p>

                              <p v-else style="padding-left:15px; font-size: 0.6rem;height:20px;">Son Pesos: {{ facturaSeleccionada.PriceLetters }}  Cheque a la orden del Banco de la Nacion Argentina <br>Banco {{ facturaSeleccionada.U_CheckBankName }}. Cheque {{(facturaSeleccionada.U_CheckNumber)?facturaSeleccionada.U_CheckNumber:'' }}. Vto: {{(facturaSeleccionada.U_CheckDueDate)?facturaSeleccionada.U_CheckDueDate:''}}</p>

                      </td>
                  </tr>

                  </tbody>
                </table>
              </v-col>
            </v-row>
            <!--Fin BNA-->
            <v-row  v-else-if="facturaSeleccionada.U_FPago == 6">
            <!--Inicio BTB-->
              <v-col cols="12" md="12" class="">
                <table id="deposito" class="table-5" style="">
                  <tbody>
                    <tr>
                        <td style="width:40px; border: 1px solid #000;"></td>
                        <td style=" border: 1px solid #000;">
                            <table style="padding-left:5px">
                                <tr>
                                    <td style="width:30%;min-width:30%;">
                                        <img src="/img/interbanking.png" width="140" height="30" border="0" style="margin-top:0px;">
                                    </td>
                                    <td style="width:250px;padding-top:10px;">
                                        <span v-if="facturaSeleccionada.db.indexOf('mj') > -1" class="bold" style="font-size:0.7rem">
                                            RA AUTOM LEYES 23283 Y 23412 - 1601138/20
                                        </span>
                                    </td>
                                    <td style="margin-top: 2px;;margin-right:0px">
                                        <span class="bold" style="font-size:0.7rem;text-align:end;border: 1px solid #000;padding: 2px;margin-bottom: 0px;">
                                            Boleta de Depósito N° {{ facturaSeleccionada.U_RefPedido }}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table class="bold" style="padding-left:7px;font-size:0.7rem;">
                                <tr>
                                    <td style="width: 390px;">
                                        Convenio Recaudacion N° {{ facturaSeleccionada.convenio_recaudacion }}
                                    </td>
                                    <td style="width: 200px;text-align:center">
                                        {{ facturaSeleccionada.deposit_date }} {{ facturaSeleccionada.deposit_time }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Depositante: {{ facturaSeleccionada.depositante }} C.U.I.T: {{ facturaSeleccionada.depositante_cuit }}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table class="table-5">
                                            <tr>
                                                <td class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                    Vencimiento: {{ facturaSeleccionada.vencimiento }}
                                                </td>
                                                <td class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                    Importe: ${{ $formatMoney(facturaSeleccionada.DocTotal) }}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <p class="bold" style="font-size: 1.5rem; width:400px;text-align:center;height:30px">{{ facturaSeleccionada.estado }}</p>
                            <p style="padding-left:15px;font-size: 0.6rem;height:20px">Son Pesos: {{ facturaSeleccionada.PriceLetters }}</p>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <!--Fin BTB-->
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="12" sm="12" >
                <table class="table-5">
                  <tbody>
                    <tr>
                      <td class="border-box">
                        Exceptuado del cumplimiento R.G. 1415/2003, según inciso a) del apartado 'A' del anexo I
                      </td>
                    </tr>
                  </tbody>
                </table>

              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" class="pa-4">
          <p class="text-h4 text-center mt-4 pb-6" style="">
            Facturas generadas
          </p>

          <v-row
            v-for="(f, index) in facturas"
            :key="index"
            :style="index > 0 ? 'border-top: #ddd solid 1px;' : ''"
          >
            <v-col cols="12" md="10">
              <span class="text-inter" style="color: #0078d4">{{ (f.db)?f.db:'' }} </span
              ><span style="color: #000">Nro Pedido: {{ f.U_RefPedido }}</span>
            </v-col>
            <v-col cols="12" md="2">
              <v-icon @click="loadData(index)" style="color: #333"
                >mdi-eye</v-icon
              >
              <v-icon @click="download(index)" style="color: #333"
                >mdi-download</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import AxiosApi from "../../plugins/axiosApi";
import Vue from "vue";

export default {
  components: {},

  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    isLoading: false,
    datosCargados: false,
    /*empresa:{'00001':{
        empresa: "ACARA",
        direccion: "Av. Cordoba 3371 (1187), CABA",
        telefonoCorreo: "115236 8500 | socios@acara.org.ar",
        logo: "acara_logo_2.png",
    },
    '00002':{
        empresa: "MINISTERIO DE JUSTICIA",
        direccion: "Av. Cordoba 3371 (1187), CABA",
        telefonoCorreo: "115300 4000 | infoanm@jus.gob.ar",
        logo: "mj_logo.png",
    },*/
    empresa:[],
    facturas: [],
    facturaSeleccionada: {
      isBoletaAnmac: false,
    },

    columDominio : false
  }),

  async mounted() {
    this.empresa[this.$db("ACARA")] = {
      empresa: "ACARA",
      direccion: "Av. Cordoba 3371 (1187), CABA",
      telefonoCorreo: "115236 8500 | socios@acara.org.ar",
      logo: "acara_logo_2.png"
    };
    this.empresa[this.$db("MJ")] = {
      empresa: "MINISTERIO DE JUSTICIA",
      direccion: "Av. Cordoba 3371 (1187), CABA",
      telefonoCorreo: "115300 4000 | infoanm@jus.gob.ar",
      logo: "mj_logo.png"
    };
    this.empresa[this.$db("ANMAC")] = {
      empresa: "MINISTERIO DE JUSTICIA",
      direccion: "Av. Cordoba 3371 (1187), CABA",
      telefonoCorreo: "115300 4000 | infoanm@jus.gob.ar",
      logo: "mj_logo.png"
    };
    this.facturas = this.$route.params.facturas;
    this.isLoading = true;
    await this.loadData(0);
    this.isLoading = false;
    this.datosCargados = true;

    /*this.facturaSeleccionada = this.facturas[0]; // Selecciona el primer elemento al cargar

    this.facturaSeleccionada.DocumentLines.forEach((item) => {
      console.log(item);
      console.log("item.U_SEIDORAR_DistNuD");
      console.log(item.U_SEIDORAR_DistNuD);
      console.log("item.U_SEIDORAR_DistNuH");
      console.log(item.U_SEIDORAR_DistNuH);
      //si es distinto de anmac, verifico si tengo q mostrar la col dominio
      if(this.facturaSeleccionada.db != this.$db("ANMAC")){
          if(item.U_SEIDORAR_DistNuD && item.U_SEIDORAR_DistNuD != null) {
          this.columDominio = true;
          return;
        }
      }

    });*/
  },

  methods: {
    numberToWords(number) {
      if (isNaN(number) || number < 0) return "Número inválido";

      const unidades = ["", "uno", "dos", "tres", "cuatro", "cinco", "seis", "siete", "ocho", "nueve"];
      const especiales = ["diez", "once", "doce", "trece", "catorce", "quince", "dieciséis", "diecisiete", "dieciocho", "diecinueve"];
      const decenas = ["", "", "veinte", "treinta", "cuarenta", "cincuenta", "sesenta", "setenta", "ochenta", "noventa"];
      const centenas = ["", "cien", "doscientos", "trescientos", "cuatrocientos", "quinientos", "seiscientos", "setecientos", "ochocientos", "novecientos"];

      function convertirMenor1000(num) {
        if (num === 0) return "";
        if (num < 10) return unidades[num];
        if (num < 20) return especiales[num - 10];
        if (num < 100) return decenas[Math.floor(num / 10)] + (num % 10 !== 0 ? " y " + unidades[num % 10] : "");

        let centena = centenas[Math.floor(num / 100)];
        let resto = num % 100;
        let restoTexto = convertirMenor1000(resto);

        if (num === 100) return "cien";
        return centena + (resto !== 0 ? " " + restoTexto : "");
      }

      function convertirNumero(num) {
        if (num === 0) return "cero";

        let partes = [];
        let millones = Math.floor(num / 1000000);
        let miles = Math.floor((num % 1000000) / 1000);
        let cientos = num % 1000;

        if (millones > 0) {
          partes.push(millones === 1 ? "un millón" : convertirMenor1000(millones) + " millones");
        }

        if (miles > 0) {
          partes.push(miles === 1 ? "mil" : convertirMenor1000(miles) + " mil");
        }

        if (cientos > 0) {
          partes.push(convertirMenor1000(cientos));
        }

        return partes.join(" ").trim();
      }

      let entero = Math.floor(number);
      let decimal = Math.round((number - entero) * 100);

      let parteEnteraEnLetras = convertirNumero(entero);
      let parteDecimalEnLetras = decimal > 0 ? `con ${decimal}/100` : "0/100";

      return `${parteEnteraEnLetras} ${parteDecimalEnLetras}`;
    },
    async loadData(index) {
      this.isLoading = true;

      const seleccionada = {
        ...this.facturas[index],
        isBoletaAnmac: this.facturas[index].db === this.$db("ANMAC")
      };

      if (!seleccionada.isBoletaAnmac) {
        this.facturaSeleccionada = seleccionada;
        const isPatente = this.facturaSeleccionada.DocumentLines.some(obj => obj.U_SEIDORAR_DistNuD && obj.U_SEIDORAR_DistNuD != null);
        console.log("isPatente");
        console.log(isPatente);
        if(isPatente){
          this.columDominio = true;
        }
        /*if(item.U_SEIDORAR_DistNuD && item.U_SEIDORAR_DistNuD != null) {
          this.columDominio = true;
        }*/

        this.isLoading = false;
        return;
      }

      try {
        const seleccionada = {
          ...this.facturas[index],
          isBoletaAnmac: this.facturas[index].db === this.$db("ANMAC")
        };

        this.$set(this, 'facturaSeleccionada', seleccionada);

        const urlDeleg = `${this.$axiosApi.routes["bussinessPartnerClient"]}/${seleccionada.U_Deleg}`;

        let respDeleg = await this.$axiosApi.sendRequest(urlDeleg, "GET");

        if (respDeleg?.data?.data) {
          const delegData = respDeleg.data.data;

          const city = delegData.BPAddresses?.[0]?.City || delegData.City || '';
          const cardName = delegData.CardName || '';
          const address = delegData.Address || '';

          this.facturaSeleccionada.U_DelegName = cardName;
          this.facturaSeleccionada.U_DelegAddress = `${address} - ${city}`;
        }

        const metodoPago = seleccionada.U_FPago;

        if (seleccionada.isBoletaAnmac && [2, 4, 5].includes(metodoPago)) {
          try {
            const filterValue = seleccionada.U_RefPedido; // Ej: "59"
            const urlIncoming = `${this.$axiosApi.routes["incomingPayments"]}/${seleccionada.db}?refPedido=${filterValue}`;

            let respIncoming = await this.$axiosApi.sendRequest(urlIncoming, "GET");

            if (
              respIncoming.data?.data?.value?.length > 0
            ) {
              let relatedPayment = respIncoming.data.data.value[0];

              this.facturaSeleccionada.payment = {
                DocNum: relatedPayment.DocNum,
                SumApplied: relatedPayment.PaymentInvoices?.[0]?.SumApplied ?? 0,
                SumAppliedLetters: this.numberToWords(relatedPayment.PaymentInvoices?.[0]?.SumApplied ?? 0),
                DocDate: relatedPayment.DocDate,
                DueDate: relatedPayment.DueDate,
              };

              if (metodoPago == 4 && relatedPayment.TransferAccount) {
                const filterChart = `Code eq '${relatedPayment.TransferAccount}'`;
                const urlChart = `${this.$axiosApi.routes["chartAccounts"]}/${seleccionada.db}?filter=${filterChart}`;

                let respAcct = await this.$axiosApi.sendRequest(urlChart, "GET");
                if (respAcct.data?.data?.value?.length > 0) {
                  this.facturaSeleccionada.payment.CreditCardName = respAcct.data.data.value[0].Name || "Cuenta desconocida";
                } else {
                  this.facturaSeleccionada.payment.CreditCardName = "Cuenta desconocida";
                }

                this.facturaSeleccionada.payment.VoucherNum = relatedPayment.TransferReference || null;
              }

              if (relatedPayment.PaymentCreditCards?.length > 0 && metodoPago == 2) {
                const ccCode = relatedPayment.PaymentCreditCards[0].CreditCard;
                const filterCC = `CreditCardCode eq ${ccCode}`;
                let urlCC = `${this.$axiosApi.routes["creditCards"]}/${seleccionada.db}?filter=${encodeURIComponent(filterCC)}`;

                let respCC = await this.$axiosApi.sendRequest(urlCC, "GET");
                if (respCC.data?.data?.value?.length > 0) {
                  this.facturaSeleccionada.payment.CreditCardName = respCC.data.data.value[0].CreditCardName;
                }
              }
            }
          } catch (error) {
            console.error("Error en la consulta de pagos:", error);
            this.$handleAxiosError(error);
          }
        }
      } catch (error) {
        console.error("Error en loadData:", error);
      } finally {
        this.isLoading = false;
      }
    },
    download(index) {
      Swal.alertGetInfo("Buscando información");
      let id = this.facturas[index].DocEntry;
      let db = this.facturas[index].db;
      let mp = this.facturas[index].U_FPago;
      let url = '';
      console.log("mp", mp);
      if(this.facturas[index].PointOfIssueCode){
        url = (mp != 5 && mp != 4 && mp != 2) ?"/api/servicelayer/pdf/drafts/"+id+"/"+db:"/api/servicelayer/pdf/invoices/"+id+"/"+db;
      }
      else{
        url = "/api/servicelayer/pdf/orders/"+id+"/"+db;
      }

      this.$axiosApi
        .sendRequestPdfLoad(url, "GET")
        .then((response) => {
          Swal.close();
          //this.isLoading = false;
          if (response.data.size <= 0) {
            Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(console.error);
    },
  },
  watch: {},
  computed: {
    convenioRecaudacion() {
      const dbCode = this.facturaSeleccionada.db;
      const convenioData = this.$store.state.conveniosRecaudacion[dbCode];
      return convenioData ? convenioData.U_NroConvenio : "";
    },
    numeroLegal() {
      if (!this.facturaSeleccionada) return "";
      const pointOfIssue = String(this.facturaSeleccionada.PointOfIssueCode || "").padStart(5, "0");
      const folio = String(this.facturaSeleccionada.DocEntry || "").padStart(8, "0");
      return `${pointOfIssue}-${folio}`;
    },
  },
};
</script>

<style scoped>
.border-box {
  border: 1px solid #000000;


  border: 1px solid #000; /* Bordes en todos los lados */
            padding: 5px;
            box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
}
.table-th {
  color: #757575;
  font-weight: 500;
}

.table-td {
  color: #000000;
  border-bottom: #ddd solid 1px;
  font-size: 12px !important;
}
.borderBottom {
  border-bottom: #ddd solid 1px;
}

.text-h4 {
  font-weight: 700 !important;
  font-family: "Encode Sans" !important;
}
.text-inter {
  font-family: "Inter", sans-serif;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.table-5 {
  width: 98%;
  border-collapse: collapse;
  margin: 5px 5px 5px 5px;
  font-family: "Noto Sans", sans-serif;
}

</style>
